import React from 'react';
import { Link, Logo, Paragraph, RichText, Wrap } from '@leafwell/components';

import SocialItems from './social-items';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useEventsTracker } from 'contexts/eventsTracker';
import {
  useFeatureFlagEnabled,
  useFeatureFlagVariantKey,
} from 'posthog-js/react';
import LocaleSwitch from './locale-switch';

type FooterProps = {
  showNavFooter: boolean;
};

const Footer: React.FC<FooterProps> = ({ showNavFooter = true }) => {
  const { contacts, copyright, footerMenu, siteTitle, topStates, statePages } =
    useWordPressContext();

  const openStatesList = statePages.filter(({ code }) =>
    topStates.includes(code),
  );

  const copyrightText = React.useMemo(() => {
    const date = new Date();
    return copyright.replace('[year]', date.getFullYear().toString());
  }, [copyright]);

  const listInlineStyle = {
    gridTemplateRows: `repeat(${Math.ceil(openStatesList?.length / 3)}, 0.5fr)`,
  };

  // display new design for login button
  const loginExperimentEnabled = useFeatureFlagEnabled(
    'website-log-in-button-on-menu',
  );
  const loginExperimentVariant = useFeatureFlagVariantKey(
    'website-log-in-button-on-menu',
  );

  const { trackEvent } = useEventsTracker();

  return (
    <Wrap
      size="large"
      tagName="footer"
      className="md:grid-cols-[1fr_3fr_2fr] xl:grid-cols-12 gap-x-6 xl:gap-x-3"
    >
      {showNavFooter ? (
        <>
          {loginExperimentEnabled && loginExperimentVariant !== 'control' ? (
            <span className="xl:col-span-2 flex justify-between md:flex-col gap-y-8">
              <Logo variant="icon" title={siteTitle} className="lg:w-20" />
              <span className="flex gap-x-4 items-center">
                <p className="text-base">Switch language</p>
                <LocaleSwitch />
              </span>
            </span>
          ) : (
            <Logo variant="icon" title={siteTitle} className="lg:w-20" />
          )}
          {footerMenu?.length > 0 && (
            <nav className="grid gap-y-3 md:gap-y-6 gap-x-6 lg:gap-x-10 xl:grid-cols-2 xl:col-span-7 xl:col-start-3 self-start">
              {footerMenu?.length > 0 ? (
                <div className="flex flex-col gap-y-3 gap-x-6 lg:gap-x-10">
                  <div className="grid grid-cols-2 gap-y-3 gap-x-6 lg:gap-x-10">
                    {footerMenu?.map(({ title, url }, key) => (
                      <Link
                        key={key}
                        href={url}
                        target="_self"
                        variant="underlineHover"
                        onClick={() =>
                          trackEvent({
                            event: 'select_content',
                            content_type: title,
                            item_id: 'page',
                            origin: 'footer',
                          })
                        }
                      >
                        {title}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : null}
              {openStatesList?.length > 0 ? (
                <div className="grid gap-y-3">
                  <Paragraph className="mt-3 md:mt-0 font-medium">
                    Our Top States
                  </Paragraph>
                  <div
                    className="grid grid-cols-3 grid-flow-col gap-y-2 md:gap-y-2.5 md:gap-x-4"
                    style={listInlineStyle}
                  >
                    {openStatesList?.map(({ label, uri }, idx) => (
                      <Link
                        key={`state-page-${idx}`}
                        href={uri}
                        target="_self"
                        variant="underlineHover"
                        className="text-sm"
                        onClick={() =>
                          trackEvent({
                            event: 'select_content',
                            content_type: label,
                            item_id: 'page',
                            origin: 'footer',
                          })
                        }
                      >
                        {label}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : null}
            </nav>
          )}
          <section className="grid gap-y-2 md:gap-y-6 content-start md:text-right justify-items-start md:justify-items-end md:justify-end xl:col-start-10 xl:col-span-3">
            <SocialItems variant="withIcon" />
          </section>
        </>
      ) : null}
      <div className="grid xl:col-span-5 gap-y-4">
        {!showNavFooter ? (
          <>
            <Logo variant="icon" classNameButton="w-8" />
            <div className="flex flex-col xl:flex-row xl:items-end gap-x-8 gap-y-4">
              <RichText
                content={contacts}
                className="text-sm"
                tagName="section"
                suppressHydrationWarning
              />
              <Link href="/privacy-policy" target="_blank" variant="underline">
                Privacy Policy
              </Link>
            </div>
          </>
        ) : (
          <RichText
            content={contacts}
            className="text-sm"
            tagName="section"
            suppressHydrationWarning
          />
        )}
      </div>
      <Paragraph
        size="xsmall"
        className="self-end md:justify-self-end md:col-span-2 xl:col-span-7"
      >
        {copyrightText}
      </Paragraph>
    </Wrap>
  );
};

export default Footer;
